import React from "react";
import events from "../helpers/cleverTapEvents";

const InputBox = ({ dataObj, onChange }) => {
  return (
    <div>
      <div className="form-group">
        <div className="question_text" style={{marginBottom:"0"}}>
           <label htmlFor={dataObj.question} style={{margin:0}}>
              <span className={ dataObj.required ? "required" : ""}>{dataObj.question} </span>
           </label>
        </div>
     
        <input
          type="text"
          className="form-control text-field"
          id={dataObj.question}
          aria-describedby="emailHelp"
          placeholder=""
          name={dataObj.question}
          onChange={onChange}
          required={dataObj.required}
          style={{
            width: "50%"
          }}
          onBlur={(event)=>{
              events.logClevertapEvent("Question Attempted",{
                  "ANSWER":event.target.value,
                  "QUESTION":dataObj.question,
              })
          }}
        />
      </div>
    </div>
  );
};

export default InputBox;
