import React from "react";
import { Modal } from "react-bootstrap";

const ThankYou = props => {
  const link = props.text?props.text.match(/(https?:\/\/[^\s]+)/g):null;
  return (
    <Modal.Dialog>
      <Modal.Header>
        <Modal.Title>Thank You!</Modal.Title>
      </Modal.Header>

      <Modal.Body>
    <p>
      {link ? props.text.replace(link,''):props.text} 
      {
        link 
          && 
        <a href={link}> {link} </a>
      }
    </p>
      </Modal.Body>

      <Modal.Footer>
        <a className="btn btn-primary" href="https://www.rebelfoods.co">
          Close
        </a>
      </Modal.Footer>
    </Modal.Dialog>
  );
};

export default ThankYou;
