import React from "react";
import queryString from "query-string";
import CheckBox from "./CheckBox";
import RadioBox from "./RadioBox";
import InputBox from "./InputBox";
import Custom from "./Custom";
import EmojiQuestion from "./EmojiQuestion";
import ThankYou from "./ThankYou";
import Error from "./Error";
import loading from "../images/loading.gif";
import events from '../helpers/cleverTapEvents'

const RenderHTML = props => (
  <span dangerouslySetInnerHTML={{ __html: props.HTML }}></span>
);
const DefaultThankYouMessage= "Your response has been submitted successfully!"
class FeedBackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: null,
      header_data: null,
      page_styles: null,
      successful_submit: false,
      error_occurred: false,
      error_message: null,
      elementError:null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCustomInput = this.handleCustomInput.bind(this);
  }
  async componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const order_id = values.order_id;
    this.setState(prevState => ({
      ...prevState,
      order_id: order_id
    }));
    let data;
    try {
      const response = await fetch(
          `${process.env.REACT_APP_GATEWAY_URL}feedback_form_content.json?order_id=${order_id}`
      );
      data = await response.json();
    } catch (error) {
      this.setState({
        error_occurred: true,
        error_message: "There is no such order"
      });
      // window.location.assign(process.env.REACT_APP_REDIRECT_URL);
      return;
    }
    if (data.status === "failed") {
      this.setState({
        error_occurred: true,
        error_message: data.message
      });
      return;
    }
    const {form_content, order_details} = data;
    const {brand_id, styles, sections} = form_content;

    const sectionsObj = sections.filter(
        section => section.type === "questions"
    )[0];

    const header_data = sections.find(s => s.type === "header");

    let questionsObj = {};
    sectionsObj.controls.map(dataObj => {
      if (dataObj.type === "custom") {
        const customObj = {};
        dataObj.controls.map(questionObj => {
          customObj[questionObj.question] = "";
        });
        // this.setState({
        questionsObj[dataObj.question] = dataObj.required
            ? {required: true, ...customObj}
            : {};

        if (dataObj.condition) {
          questionsObj[dataObj.question].condition = true;
          questionsObj[dataObj.question].dependent = sectionsObj.controls[dataObj.condition.dependent].question;
          questionsObj[dataObj.question].dependentValues = dataObj.condition.values;
        }
        // });
      } else {
        // this.setState({
        questionsObj[dataObj.question] = dataObj.required
            ? {required: true}
            : {};
        if (dataObj.options && dataObj.options.length && dataObj.options[0].message) {
          questionsObj[dataObj.question].options = dataObj.options;
        }
        // });
      }
    });
    this.setState({
      loading: false,
      ...questionsObj,
      header_data,
      page_styles: styles,
      data: sectionsObj.controls,
      thankYouText: DefaultThankYouMessage,
      order_details,
      brand_id: brand_id,
      initialQuestObj: {
        ...questionsObj
      }
    });
    events.logClevertapEvent("PAGE VISIT", {
      "SCREEN NAME": "Home",
      "CUSTOMER NAME": order_details && order_details.customer && order_details.customer.name ? order_details.customer.name : "",
      "USER ID":order_details && order_details.customer && order_details.customer.customer_id?order_details.customer.customer_id:"",
      "BRAND NAME": order_details && order_details.brands && order_details.brands.length > 0 ? order_details.brands[0].brand_name : "",
      "BRAND ID": order_details && order_details.brands && order_details.brands.length > 0 ? order_details.brands[0].brand_id : "",
      "CITY": order_details && order_details.location ? order_details.location.city_name : "",
      "STORE_ID": order_details && order_details.store && order_details.store.id ? order_details.store.id : "",
      "ORDER_SOURCE": order_details.order_source,
      "ORDER_CRN": order_details.order_crn,
      "CLIENT_SOURCE_ID": order_details.client_source_id,
      "ORDER_DATE": order_details.order_date,
      "CART_AMOUNT": order_details.cart_amount,
      "STORE": order_details && order_details.store && order_details.store && order_details.store.store ? order_details.store.store : "",
      "COUNTRY": order_details && order_details.store && order_details.store.country_code ? order_details.store.country_code : "",
      "NPS TAG": "DEFAULT"
    })
    events.createUserProfile({
      name:order_details && order_details.customer && order_details.customer.name ? order_details.customer.name : "",
      mailing_address:order_details && order_details.customer && order_details.customer.mailing_address?order_details.customer.mailing_address:"",
      active_dialing_code:order_details && order_details.customer && order_details.customer.active_dialing_code?order_details.customer.active_dialing_code:"",
      phone_number:order_details && order_details.customer && order_details.customer.phone_number?order_details.customer.phone_number:""

    })

  }

  handleSubmit(event) {
    let submitAnswer=true
    event.preventDefault();
    const {
      loading,
      data,
      order_details,
      header_data,
      page_styles,
      successful_submit,
      order_id,
      error_occurred,
      error_message,
      thankYouText,
      elementError,
      brand_id,
      initialQuestObj,
      ...formData
    } = this.state;
    const question_responses = Object.keys(formData).reduce((acc, key) => {
      if (formData[key].answer) {
        acc.push({ [key]: formData[key].answer });
      } else {
        if(formData[key].condition) {
          if(formData[key].conditionSubmit) {
            delete formData[key].conditionSubmit;
            delete formData[key].condition;
            delete formData[key].dependent;
            delete formData[key].dependentValues;
            const { required, ...answers } = formData[key];
            acc.push({ [key]: answers });
          } else {
          }
        } else {
          const { required, ...answers } = formData[key];
          if (required ) {
            submitAnswer=false
          } 
        
          acc.push({ [key]: answers });
        }
      }
      return acc;
    }, []);
    const formObj = {
      order_id: order_id,
      question_responses: JSON.stringify(question_responses)
    };
    const form_response = {
      form_response: formObj
    };

    if (submitAnswer) {
      
      fetch(`${process.env.REACT_APP_GATEWAY_URL}save_form_content.json`, {
        method: "post",
        body: JSON.stringify(form_response),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success) {
            this.setState(prevState => ({
              ...prevState,
              successful_submit: true
            }));
          }
        })
        .catch(err => {
        });
    }
    else{
  
      this.setState({
        elementError:'please fill all the required input '
      })
    }
    events.logClevertapEvent("SUBMIT",{
      "CUSTOMER NAME":order_details && order_details.customer && order_details.customer.name?order_details.customer.name:"",
      "BRAND":order_details && order_details.brands && order_details.brands.length>0 ?order_details.brands[0].brand_name:"",
      "ORDER_CRN":order_details.order_crn,
      "ORDER_SOURCE":order_details.order_source,
      "CLIENT_SOURCE_ID":order_details.client_source_id,
    });
    }
  handleRadioChange(event,quesType) {
    if(quesType==='emoji_question' && ['3','4','5'].includes(this.state.brand_id) ){
      let question_responses = [];
      question_responses.push({
        [event.target.name]:event.target.value
      })
      const formObj = {
        order_id: this.state.order_id,
        question_responses: JSON.stringify(question_responses)
      };
      const form_response = {
        form_response: formObj
      };      
        fetch(`${process.env.REACT_APP_GATEWAY_URL}save_form_content.json`, {
          method: "post",
          body: JSON.stringify(form_response),
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            if (data.success) {
            }
          })
          .catch(err => {
          });
    }
    
    if(this.state[event.target.name].options) {
        var thankYouMessage = DefaultThankYouMessage;
        thankYouMessage = this.state[event.target.name].options.filter((item)=> item.name === event.target.value)[0].message
      if(quesType==='emoji_question' && ['3','4','5'].includes(this.state.brand_id) ){
          this.setState({
            ...this.state.initialQuestObj,
            [event.target.name]: {
              ...this.state[event.target.name],
              answer: event.target.value,
            },
            thankYouText: thankYouMessage
          });
      }else{
          this.setState({
            [event.target.name]: {
              ...this.state[event.target.name],
              answer: event.target.value,
            },
            thankYouText: thankYouMessage
          });
          
        }
        
      } else {
          this.setState({
            [event.target.name]: {
              ...this.state[event.target.name],
              answer: event.target.value,
            }
          });
      }

    if(quesType==='emoji_question'){
    events.logClevertapEvent("emoji_question",{
      "VALUE":event.target.value,
      "CUSTOMER NAME":this.state.order_details && this.state.order_details.customer && this.state.order_details.customer.name?this.state.order_details.customer.name:"",
      "BRAND":this.state.order_details && this.state.order_details.brands && this.state.order_details.brands.length>0 ?this.state.order_details.brands[0].brand_name:"",
      "ORDER_CRN":this.state.order_details.order_crn,
      "ORDER_SOURCE":this.state.order_details.order_source,
    })
    }
  }
  handleInputChange(event) {
    this.setState({
      [event.target.name]: {
        ...this.state[event.target.name],
        answer: event.target.value
      }
    });
  }
  handleCheckChange(event) {
    let checked = this.state[event.target.name]["answer"]
      ? [...this.state[event.target.name]["answer"]]
      : [];
    if (event.target.checked) {
      checked.push(event.target.value);
    } else {
      checked = checked.filter(option => option !== event.target.value);
    }
    this.setState({
      [event.target.name]: {
        ...this.state[event.target.name],
        answer: [...checked]
      }
    });
  }

  handleCustomInput(event, question,questionType) {
    if (questionType=="checkbox") {
      
      let checked = this.state[question] && this.state[question][event.target.name] && this.state[question][event.target.name]["answer"]
      ? [...this.state[question][event.target.name]["answer"]]
      : [];
    if (event.target.checked) {
      checked.push(event.target.value);
    } else {
      checked = checked.filter(option => option !== event.target.value);
    }
    const answerObj = { ...this.state[question] };
    
    answerObj[event.target.name]= {
        ...this.state[event.target.name],
        answer: [...checked]
      }
      this.setState({
        [question]: answerObj
      });
      events.logClevertapEvent("Question Attempted",{
        "ANSWER":event.target.value,
        "QUESTION":questionType,
        "CHECKED":event.target.checked,
        "CUSTOMER NAME":this.state.order_details && this.state.order_details.customer && this.state.order_details.customer.name?this.state.order_details.customer.name:"",
        "BRAND":this.state.order_details && this.state.order_details.brands && this.state.order_details.brands.length>0 ?this.state.order_details.brands[0].brand_name:"",
        "ORDER_CRN":this.state.order_details.order_crn,
        "ORDER_SOURCE":this.state.order_details.order_source,
      })
    } else {
      const answerObj = { ...this.state[question] };
      answerObj[event.target.name] = event.target.value;
      this.setState({
        [question]: answerObj
      });
      if (questionType!=='text_field'){
        events.logClevertapEvent("Question Attempted",{
          "ANSWER":event.target.value,
          "QUESTION":questionType,
          "CUSTOMER NAME":this.state.order_details && this.state.order_details.customer && this.state.order_details.customer.name?this.state.order_details.customer.name:"",
          "BRAND":this.state.order_details && this.state.order_details.brands && this.state.order_details.brands.length>0 ?this.state.order_details.brands[0].brand_name:"",
          "ORDER_CRN":this.state.order_details.order_crn,
          "ORDER_SOURCE":this.state.order_details.order_source,
        })
      }

    }

   
  }

  render() {
    if (this.state.error_occurred) {
      return <Error error_message={this.state.error_message} />;
    }
    if (this.state.loading) {
      const loadingStyle = {
        position: "fixed",
        top: "0px",
        right: "0px",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        backgroundImage: `url(${loading})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        zIndex: "10000000",
        opacity: "0.4"
      };
      return <div style={loadingStyle}></div>;
    }
    return (
      <div
        className="page"
        style={{
          backgroundColor: this.state.page_styles
            ? this.state.page_styles.backgroundColor
            : ""
        }}
      >
        <div
    style={{
      backgroundImage:
          "url(" +
          (this.state.page_styles
              ? this.state.page_styles.backgroundImage
                  ? this.state.page_styles.backgroundImage
                  : this.state.page_styles.header_background
                      ? this.state.page_styles.header_background.color
                      : ""
              : "") +
          ")"
    }}
    id="background-container"
    />
        {this.state.successful_submit ? (
          <ThankYou text={this.state.thankYouText}/>
        ) : (
          <form onSubmit={this.handleSubmit}>
            <div className="form-container" style={{ marginTop: "-100px" }}>
              <div className="form-content">
                <div className="header-container">
                  {this.state.header_data ? (
                    <React.Fragment>
                      <h2 className="title">
                        <RenderHTML HTML={this.state.header_data.title} />{" "}
                      </h2>
                      <div className="subtitle">
                        <p>
                          <RenderHTML HTML={this.state.header_data.subtitle} />{" "}
                        </p>
                        <p className="danger-text">* Required</p>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
                {/* <div className="order-details">
                  <OrderDetails order_details={this.state.order_details} />
                </div> */}
                <div className="question-list">
                  {this.state.data.map((dataObj, ind) => {
                    switch (dataObj.type) {
                      case "checkbox":
                        return (
                          <div className="question-item" key={ind}>
                            <CheckBox
                              dataObj={dataObj}
                              onChange={this.handleCheckChange}
                            />
                          </div>
                        );

                      case "radio":
                        return (
                          <div key={dataObj.question} className="question-item">
                            <RadioBox
                              dataObj={dataObj}
                              onChange={this.handleRadioChange}
                              radioState={this.state[dataObj.question] || {}}
                              questionIndex={ind}
                            />
                          </div>
                        );

                      case "text_field":
                        return (
                          <div key={dataObj.question} className="question-item">
                            <InputBox
                              dataObj={dataObj}
                              onChange={this.handleInputChange}
                            />
                          </div>
                        );
                        case "emoji_question":
                          return (
                            <div key={dataObj.question} className="question-item">
                              <EmojiQuestion
                                dataObj={dataObj}
                                onChange={this.handleRadioChange}
                                radioState={this.state[dataObj.question] || {}}
                                questionIndex= {ind}
                                quesType='emoji_question'
                              />
                            </div>
                          );

                      case "custom":
                        let display = false;
                        if(dataObj.condition) {
                          var isDependent = false;
                          var answerObject = ()=>{
                            
                            if(Array.isArray(this.state[this.state[dataObj.question].dependent].answer)){
                              return this.state[this.state[dataObj.question].dependent].answer;
                            }else{
                              if(this.state[this.state[dataObj.question].dependent]){
                                for (const key in this.state[this.state[dataObj.question].dependent]) {
                                  if ( Object.keys(this.state[this.state[dataObj.question].dependent][key]).includes('answer')) {
                                    return this.state[this.state[dataObj.question].dependent][key]['answer']
                                  }
                                }
                              }
                            }
                          }  



                          if(answerObject()  ) {
                            let arr1 = this.state[dataObj.question].dependentValues;
                            arr1.forEach(el=>{
                              let arr2 = answerObject();
                              if(Array.isArray(arr2) && arr2.filter(elnew=>(elnew===el)).length>0 && !isDependent){
                                  isDependent = true;
                              };
                            });
                          }else{
                            if((this.state[dataObj.question].dependentValues.indexOf(this.state[this.state[dataObj.question].dependent].answer) > -1)){
                              isDependent=true;
                            }
                          }
                          
                          if((this.state[this.state[dataObj.question].dependent].answer || answerObject()) && isDependent) {
                            display = true;
                            this.state[dataObj.question].conditionSubmit = true;
                          } else {
                            this.state[dataObj.question].conditionSubmit = false;
                          }
                        } else{
                          display = true;
                        }
                        return (
                          display &&
                          <div key={dataObj.question} className="question-item">
                            <Custom
                              dataObj={dataObj}
                              onChange={this.handleCustomInput}
                              questionIndex={ind}
                            />
                          </div>
                        );

                      default:
                        return (
                          <div key={dataObj.question} className="question-item">
                            Don't Recognize the question type
                          </div>
                        );
                    }
                  })}
                  <input
                    className="btn btn-primary"
                    type="submit"
                    value="Submit"
                  />
          <div className='text-danger'>{this.state.elementError}</div>
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    );
  }
}

export default FeedBackForm;
