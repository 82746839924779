import React from "react";
const RadioBox = ({ dataObj, onChange, radioState, questionIndex }) => {
  const horizontal =
    dataObj.display === "horizontal" ? "form-check-inline no-margin" : "";
  let leftText = "";
  let rightText = "";
  if (dataObj.display === "horizontal") {
    leftText = dataObj.start_text;
    rightText = dataObj.end_text;
  }
  return (
    <div>
      <div className="question_text">
        <span className={ dataObj.required ? "required" : ""}>{dataObj.question}
     </span>
         </div>
      <div className="options-wrapper">
        <div className="question-options" style={{"flexDirection": (dataObj.display === "horizontal") ? "row" :"column"}}>
          {leftText ? <span className="horizontal-radio-text">{leftText}</span> : ""}
          <div className={ (dataObj.display === "horizontal") ? "radio-align-vertical" : ""}>
            {dataObj.options.map(optionObj => {

              return (
                <div key={optionObj.name} className={`form-check ${horizontal}`}>
                  <label className={ dataObj.display === 'horizontal' ? "option-align-vertical text-center" : ""}>
                    <input
                      className="form-check-input mr-0"
                      type="radio"
                      name={dataObj.question}
                      id={questionIndex+optionObj.name}
                      value={optionObj.name}
                      onChange={onChange}
                      required
                    />
                    <label className="form-check-label" htmlFor={questionIndex+optionObj.name}>
                      {optionObj.name}{optionObj.name === "Other"?":" : null}
                    </label>
                  </label>
                
                  {optionObj.name === "Other" ? (
                    <input
                      type="text"
                      className="text-field"
                      name={dataObj.question}
                      onChange={onChange}
                      required={radioState.answer === "Other"}
                    ></input>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
         
          {rightText ? <span className="horizontal-radio-text">{rightText}</span> : ""}
        </div>
      </div>
     
    </div>
  );
};

export default RadioBox;
