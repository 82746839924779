import React from "react";
import { Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Error = ({error_message}) => {
  return (
    <>
      <Helmet>
        <style>{"body { background-color: #00acf3; }"}</style>
      </Helmet>
      <Modal.Dialog style={{ backgroundColor: "#00acf3" }}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{error_message}</p>
        </Modal.Body>
        <Modal.Footer>
          <a
            className="btn btn-primary"
            href={`${process.env.REACT_APP_REDIRECT_URL}`}
          >
            Close
          </a>
        </Modal.Footer>
      </Modal.Dialog>
    </>
  );
};

export default Error;
