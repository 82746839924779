import React from "react";
const CheckBox = ({ dataObj, onChange }) => {
 
  const horizontal =
    dataObj.display === "horizontal" ? "form-check-inline" : "";
  return (
    <div>
      <div className="question_text">
         <span className={ dataObj.required ? "required" : ""}> {dataObj.question}</span>
      </div>
      <div className="options-wrapper">
        <div className="question-options"  style={{"flexDirection": (dataObj.display === "horizontal") ? "row" :"column"}}>
          {dataObj.options.map(optionObj => {
            return (
              <div key={optionObj.name} className={`form-check ${horizontal}`}>
                <label>
                  <input
                    name={dataObj.question}
                    className="form-check-input"
                    type="checkbox"
                    id={optionObj.name}
                    value={optionObj.name}
                    onChange={onChange}
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1" htmlFor={optionObj.name}>
                    {optionObj.name}
                  </label>
                </label>
                
              </div>
            );
          })}
        </div>
      </div>
      
    </div>
  );
};

export default CheckBox;
