import React from "react";
import CheckBox from "./CheckBox";
import RadioBox from "./RadioBox";
import InputBox from "./InputBox";

const Custom = ({ dataObj, onChange, questionIndex}) => {
  return (
    <React.Fragment>
      <div>
        <div className="question_text custom-header"
          style= {{ 
              "backgroundColor": dataObj.styles ? dataObj.styles.bgcolor : "rgb(252, 188, 6)",
              "color": dataObj.styles ? dataObj.styles.color : "rgba(0, 0, 0, 1)"
            }}>{dataObj.question}
            
        </div>
        <div className="instruction_text"
          style= {{ 
              "color": "grey",
              "whiteSpace":'pre',
              "fontSize": '14px'
            }}>{dataObj.instructionText}
            
        </div>
        {/* <span><svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 10" preserveAspectRatio="none">
            <polygon style={{
                  "fill": "rgb(252, 188, 6)",
                  "stroke": "rgb(252, 188, 6)"
            }} points="0,0 10,0 0,10"></polygon></svg></span> */}
      </div>

      {dataObj.controls.map((questionObj, ind) => {
        switch (questionObj.type) {
          case "checkbox":
            return (
              <div key={questionObj.question} className="nested-controls">
                <CheckBox dataObj={questionObj} onChange={event => {
                    onChange(event, dataObj.question,"checkbox");
                  }} />
              </div>
            );

          case "radio":
            return (
              <div key={questionObj.question} className="nested-controls">
                <RadioBox
                  dataObj={questionObj}
                  onChange={event => {
                    onChange(event, dataObj.question,"radio");
                  }}
                  radioState={dataObj.question[questionObj.question] || {}}
                  questionIndex = {questionIndex.toString() +ind.toString()}
                />
              </div>
            );

          case "text_field":
            return (
              <div key={questionObj.question} className="nested-controls">
                <InputBox dataObj={questionObj}  onChange={event => {
                    onChange(event, dataObj.question,"text_field");
                  }} />
              </div>
            );

          default:
        }
      })}
    </React.Fragment>
  );
};

export default Custom;
