const events = {};
try {
    const is_cleverTap_enabled = true;
    events.logClevertapEvent = function () {};
    events.logClevertapEventWithError = function () {};
    events.createUserProfile = function () {};
    events.logoutSession = function () {};

    if (is_cleverTap_enabled && typeof window !== "undefined" && window.clevertap !== "undefined") {
        events.createUserProfile = (body) => {
           if (body && body.hasOwnProperty('phone_number')) {
                window.clevertap.profile.push({
                  "Site": {
                    "Name": body.name, // User's name
                    "Email": body.mailing_address,
                    "Phone": body.active_dialing_code + body.phone_number,
                    "Identity": body.phone_number
                  }
                });
            }
        };

        events.logoutSession = () => {
            window.clevertap.logout();
        };

        events.logClevertapEvent = (event_name,detailsObj=null)=>{
                window.clevertap.event.push(event_name,detailsObj);

        }

        events.logClevertapEventWithError = (event_name,error)=>{
            const err = error && error.message ? error.message : ''
            window.clevertap.event.push(event_name, {
                'ERROR MSG': `Error in ${event_name} ${err}`
            });
        }
    }
} catch (e) {

}

module.exports = events;
