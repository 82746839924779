import React from "react";
class EmojiQuestion extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {dataObj, onChange, radioState, questionIndex,quesType} = this.props;
        const leftText = dataObj.startText;
        const rightText = dataObj.endText;
        return(
            <div>
                <div className="question_text">
                    <span className={ dataObj.required ? "required" : ""}>{dataObj.question}</span>
                    <div className="instruction_text"
                         style= {{
                             "color": "grey",
                         }}>{dataObj.instructionText}

                    </div>
                </div>
                <div className="options-wrapper">
                    <div className="question-options" style={{"flexDirection": (dataObj.display === "horizontal") ? "row" :"column"}}>
                        {leftText ? <span className="horizontal-radio-text">{leftText}</span> : ""}
                        <div className={"emoji-align-vertical"}>
                            {dataObj.options.map((optionObj, ind) => {
                                return (
                                    <div key={optionObj.name} className={"emojiOptionContainer"} >
                                        <label className={ dataObj.display === 'horizontal' ? "option-align-vertical customRadio" : ""}>
                                            <input
                                                className="form-check-input emoji-input"
                                                type="radio"
                                                name={dataObj.question}
                                                id={questionIndex+optionObj.name}
                                                value={optionObj.name}
                                                onChange={(event)=>{onChange(event,quesType)}}
                                                required
                                            />
                                            <label className={"form-check-label " +(ind == 6 || ind == 7 ? "yellow" : ind == 8 || ind == 9 ? "green" : "")} htmlFor={questionIndex+optionObj.name}>
                                                <div className="emojiContainer" ref={"emoji-"+ind} key={optionObj.name}>
                                                    <img src={optionObj.emoji} alt={optionObj.name}/>
                                                    <span className="radio-counter">{ind+1}</span>
                                                </div>
                                            </label>
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                        {rightText ? <span className="horizontal-radio-text">{rightText}</span> : ""}
                    </div>
                </div>
            </div>)
    };
};

export default EmojiQuestion;
